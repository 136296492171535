<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_cancel_invite')"
        :ok-title="$t('buttons.delete')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="cancelInvite"
        @hide="onClose"
    >
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col cols="12">
                    {{
                        $t("modals.text_cancel_invite", {
                            inviteName: getModalData.invite.Name,
                        })
                    }}
                    <span class="bold-font-style">{{
                        $t("modals.are_you_sure")
                    }}</span>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

import {
    MEMBER_CANCEL_INVITE,
    LOCATION_INVITES_GET,
} from "@/store/types/members";

import { M_MEMBER_INVITE_CANCEL } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";

export default {
    data() {
        return {
            type: M_MEMBER_INVITE_CANCEL,
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
    },
    created() {},
    methods: {
        cancelInvite() {
            this.$store
                .dispatch(MEMBER_CANCEL_INVITE, this.getModalData.invite)
                .then(() => {
                    this.$store.dispatch(
                        LOCATION_INVITES_GET,
                        this.$route.params.locationId
                    );
                });
        },
        onClose() {
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>
