<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_remove_user')"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="deleteUser"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col cols="12"  v-if="user.Location && user.User">
                    {{
                        $t("modals.text_remove_location_user", {
                            locationName: user.Location.Name,
                            userName: user.User.FirstName + " " + user.User.LastName,
                        })
                    }}
                    <span class="bold-font-style">{{
                            $t("modals.are_you_sure")
                        }}</span>
                </b-col>                
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {LOCATION_USER_DELETE, LOCATION_GET} from "@/store/types/locations";
import {M_LOCATION_USER_DELETE} from "@/components/modals/types";
import {CLEAR_MODAL_DATA} from "@/store/types/global";
import {
    COMPANY_GET_INVITES,
    COMPANY_GET_EMPLOYEES,
} from "@/store/types/companies";
import {
    MEMBER_ROLES_GET,
    LOCATION_MEMBERS_GET,
} from "@/store/types/members";

export default {
    data() {
        return {
            type: M_LOCATION_USER_DELETE,
            user: {},
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
    },
    methods: {
        setData() {           
                this.user = {...this.getModalData.user};
                if (this.user.User.FirstName == null || this.user.User.FirstName == "null" || this.user.User.FirstName == "") {
                    this.user.User.FirstName = '';
                }
                if (this.user.User.LastName == null || this.user.User.LastName == "null" || this.user.User.LastName == "") {
                    this.user.User.LastName = '';
                }           
        },
        deleteUser() {
            this.$store
                .dispatch(LOCATION_USER_DELETE, {
                    userId: this.user.User.Id,
                    locationId: this.user.Location.Id,
                })
                .then(() => {                    
                    if (this.$route.params.userId) {                      
                      this.$store
                            .dispatch(MEMBER_ROLES_GET, {
                                companyId: this.$route.params.companyId,
                                userId: this.$route.params.userId,
                            })
                        if (this.$route.params.locationId) {
                            this.$router.push({
                                name: "members",
                                params: {
                                    locationId: this.$route.params.locationId,
                                    companyId: this.$route.params.companyId,
                                },
                            });
                        }
                    }else if (this.$route.params.locationId) {
                        this.$router.push({
                            name: "members",
                            params: {
                                locationId: this.$route.params.locationId,
                                companyId: this.$route.params.companyId,
                            },
                        });
                        this.$store.dispatch(LOCATION_MEMBERS_GET, this.$route.params.locationId)
                    } else {
                        this.$store.dispatch(
                            COMPANY_GET_EMPLOYEES,
                            this.$route.params.companyId
                        );
                        if (this.$route.params.locationId) {
                            this.$router.push({
                                name: "members",
                                params: {
                                    locationId: this.$route.params.locationId,
                                    companyId: this.$route.params.companyId,
                                },
                            });
                        }
                    }
                    this.$session.set("popUpClose", true);
                    /* else if (this.$route.params.userId) {
                        this.$router.push({
                            name: "profile",
                            params: {
                                locationId: this.$route.params.locationId,
                                companyId: this.$route.params.companyId,                                
                                userId: this.$route.params.userId,
                            },
                        });
                    } */
                });
        },
        onClose() {
            this.user = null;
            this.$store.commit(CLEAR_MODAL_DATA);
        },
    },
};
</script>
