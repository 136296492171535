<template>
    <div class="main-content">
        <div class="header">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="header-pretitle">
                                {{ $t("locations.location") }}
                            </h6>
                            <h1 class="header-title text-capitalize">
                                {{ $t("users.users") }}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-container fluid>
            <b-row>
                <b-col cols="12">
                    <div class="card" v-if="getInitProcessing === false">
                        <div class="card-header">
                            <!-- Title -->
                            <h4 class="card-header-title text-capitalize"></h4>
                            <form class="mr-3">
                                <Select2
                                v-model="perPage"
                                :options="perPageOptions"
                                :settings="{
                                    minimumResultsForSearch: -1,
                                    containerCssClass:
                                    'custom-select custom-select-sm form-control-flush',
                                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                                }"
                                @change="paginatedList"
                                />
                            </form>
                            <b-button
                                variant="primary"
                                size="sm"
                                class="mr-2"
                                v-if="$can('location-staff-manage', getActiveLocation) && !roleFlag"
                                @click="createInvite"
                            >
                                {{ $t("staff.invite") }}
                            </b-button>
                            <b-button
                                variant="primary"
                                size="sm"
                                class="mr-2"
                                v-if="roleFlag"
                                @click="createInvite"
                            >
                                {{ $t("staff.invite") }}
                            </b-button>
                            <b-button
                                v-if="false"
                                variant="primary"
                                size="sm"
                                @click="inviteMember"
                                >{{ $t("keys.send_invite") }}</b-button
                            >
                        </div>
                        <div class="card-header">
                            <!-- Form -->
                            <form>
                                <div
                                    class="
                                        input-group
                                        input-group-flush
                                        input-group-merge
                                    "
                                >
                                    <!-- Input -->

                                    <b-form-input
                                        :placeholder="$t('common.search')"
                                        v-model="filter"
                                        class="
                                            form-control form-control-prepended
                                            search
                                        "
                                        type="search"
                                    ></b-form-input>

                                    <!-- Prepend -->
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- v-if="locationUsersList.length && !showLoader && !isMobile"
                        :items="locationUsersList" -->

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="locationUsersList.length"
                            :per-page="perPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <b-table
                            responsive
                            hover
                            :items="paginatedList"
                            v-if="paginatedList.length && !showLoader && !isMobile"
                            :fields="fields"
                        >
                            <template #cell(User)="data">
                                <div class="avatar avatar-xs align-middle mr-2">
                                    <router-link
                                    class="item-name text-reset"
                                    :to="{
                                        name: 'profile',
                                        params: {
                                            locationId: data.item.Location.Id,
                                            companyId: data.item.Company.Id,
                                            userId: data.item.User.Id,
                                        },
                                    }"
                                    >
                                    <img
                                        class="avatar-img rounded-circle"
                                        :src="data.item.User.SmallImageUrl"
                                        v-if="data.item.User.SmallImageUrl"
                                    />
                                    <div
                                        v-else
                                        class="
                                            avatar-title
                                            font-size-lg
                                            bg-primary-soft
                                            rounded-circle
                                            text-primary
                                        "
                                    >
                                        <!-- <i class="fi flaticon-user"></i>  style="width: 32px; height: 32px;" -->
                                        <img :src="'/images/person_MyZesec_64dp.png'"  style="width: 32px; height: 32px;"/>
                                    </div></router-link>
                                </div>
                                <router-link
                                    class="item-name text-reset"
                                    :to="{
                                        name: 'profile',
                                        params: {
                                            locationId: data.item.Location.Id,
                                            companyId: data.item.Company.Id,
                                            userId: data.item.User.Id,
                                        },
                                    }"
                                >{{" "+userFName(data.item.User.FirstName)+" "+userLName(data.item.User.LastName)}}
                                </router-link>
                            </template>
                            <!-- data.item.User.Type != 'Light' && -->
                            <template #cell(actions)="data">
                                <b-dropdown
                                    variant="outline"
                                    toggle-class="dropdown-ellipses dropdown-toggle"
                                    no-caret
                                    right
                                    toggle-tag="a"
                                    size="sm"
                                    v-if="(data.item.RolesString != '') && (data.item.User.Id != userId) && (getInitProcessing === false)"
                                >
                                <!-- v-if="data.item.RolesString != '' && $can('staff-manage', getActiveCompany)" -->
                                    <template v-slot:button-content>
                                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                                    </template>
                                    <b-dropdown-item
                                        class="text-left text-truncate"
                                        :disabled="disableChangeRole(data.item.MaxRoleName)"
                                        @click="changeRole(data.item.User)"
                                        >{{ $t("staff.change_role") }}</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        class="text-left text-truncate"
                                        :disabled="disableRemoveRole(data.item.MaxRoleName)"
                                        @click="deleteUser(data.item)"
                                        >{{ $t("buttons.remove_role") }}</b-dropdown-item
                                    >
                                </b-dropdown>
                            </template>
                            <template #cell(VisibleInIntercom)="data">
                                <b-form-checkbox
                                    v-if="data.item.RolesString != '' && data.item.MemberIds && data.item.MemberIds.length > 0"
                                    @change="VisibleInIntercomChange(data.item.MemberIds, !data.item.VisibleInIntercom)"
                                    v-model="data.item.VisibleInIntercom"
                                />
                                <div class="custom-control" v-else></div>
                            </template>
                        </b-table>
                        <!--  v-else-if="locationUsersList.length && !showLoader && isMobile"
                        :items="locationUsersList" -->
                        <b-table
                            responsive
                            hover
                            :items="paginatedList"
                            v-else-if="paginatedList.length && !showLoader && isMobile"
                            :fields="fields"
                        >
                            <template #cell(User)="data">
                                <div class="avatar avatar-xs align-middle mr-2">
                                    <router-link
                                    class="item-name text-reset"
                                    :to="{
                                        name: 'profile',
                                        params: {
                                            locationId: data.item.Location.Id,
                                            companyId: data.item.Company.Id,
                                            userId: data.item.User.Id,
                                        },
                                    }"
                                    >
                                    <img
                                        class="avatar-img rounded-circle"
                                        :src="data.item.User.SmallImageUrl"
                                        v-if="data.item.User.SmallImageUrl"
                                    />
                                    <div
                                        v-else
                                        class="
                                            avatar-title
                                            font-size-lg
                                            bg-primary-soft
                                            rounded-circle
                                            text-primary
                                        "
                                    >
                                        <!-- <i class="fi flaticon-user"></i> -->
                                        <img :src="'/images/person_MyZesec_64dp.png'" style="width: 32px; height: 32px;"/>
                                    </div></router-link>
                                </div>
                                <router-link
                                    class="item-name text-reset"
                                    :to="{
                                        name: 'profile',
                                        params: {
                                            locationId: data.item.Location.Id,
                                            companyId: data.item.Company.Id,
                                            userId: data.item.User.Id,
                                        },
                                    }"
                                    ><p style="white-space: pre;">{{userFName(data.item.User.FirstName) +"\n"+ userLName(data.item.User.LastName)}}</p></router-link
                                >
                            </template>
                            <!-- data.item.User.Type != 'Light' && -->
    <template #cell(actions)="data">
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                    size="sm"
                    v-if="(data.item.RolesString != '') && (data.item.User.Id != userId) && (getInitProcessing === false)"
                >
                <!-- v-if="data.item.RolesString != '' && $can('staff-manage', getActiveCompany)" -->
                    <template v-slot:button-content>
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        :disabled="disableChangeRole(data.item.MaxRoleName)"
                        @click="changeRole(data.item.User)"
                        >{{ $t("staff.change_role") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        class="text-left text-truncate"
                        :disabled="disableRemoveRole(data.item.MaxRoleName)"
                        @click="deleteUser(data.item)"
                        >{{ $t("buttons.remove_role") }}</b-dropdown-item
                    >
                </b-dropdown>
                            </template>
                        </b-table>
                        <empty-list
                            v-if="locationUsersList.length == 0 && !showLoader"
                            :title="$t('users.no_users')"
                        />
                        <b-col cols="12" v-if="showLoader">
                            <div
                                class="
                                    d-flex
                                    justify-content-center
                                    text-center
                                    my-3
                                "
                            >
                                <b-spinner
                                    :label="$t('common.loading')"
                                ></b-spinner>
                            </div>
                        </b-col>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="locationUsersList.length"
                            :per-page="perPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-cancel-invite-modal />
                        <zs-delete-location-user-modal />
                        <zs-delete-key-user-modal />
                        <zs-change-location-role-modal
                            :companyId="$route.params.companyId"
                            :locationId="$route.params.locationId"
                        />
                        <zs-location-invite-modal
                            :companyId="$route.params.companyId"
                            :locationId="$route.params.locationId"
                        />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import DeleteKeyUser from "@/components/modals/DeleteKeyUser";
import DeleteLocationUserModal from "@/components/modals/DeleteLocationUser";
import InviteLocationEmployeeModal from "@/components/modals/InviteLocationEmployee";
import ChangeLocationEmployeeRole from "@/components/modals/ChangeLocationEmployeeRole";
import CancelInviteMemberModal from "@/components/modals/CancelInviteMember";
import EmptyList from "@/components/common/EmptyList";
import Select2 from "@/components/common/Select2";

import { mapGetters } from "vuex";

import {
    LOCATION_MEMBERS_GET,
    MEMBER_CANCEL_INVITE,
} from "@/store/types/members";

import {
    LOCATION_GET,
} from "@/store/types/locations";

import {
    M_MEMBER_INVITE_FORM,
    M_MEMBER_INVITE_CANCEL,
    M_DELETE_KEY_USER,
    M_LOCATION_CHANGE_ROLE_FORM,
    M_COMPANY_INVITE_FORM,
    M_COMPANY_LOCATION_INVITE_FORM,
} from "@/components/modals/types";

import modalMixin from "@/mixins/modalMixin";
import {
    definedKeyRolesIdsArray,
    definedRolesArray,
    maxRoleName,
    ROLE_COMPANY_MANAGER,
    ROLE_COMPANY_OWNER,
    ROLE_DEVICE_INSTALLER,
    ROLE_KEY_MANAGER,
    ROLE_LOCATION_MANAGER,
    ROLE_KEY_PERMANENT,
    ROLE_KEY_TEMPORARY,
    rolePriorityByName
} from "@/acl/roles";
import {
    M_COMPANY_USER_DELETE,
    M_LOCATION_USER_DELETE,
} from "@/components/modals/types";
import { sleep } from "@/store/modules/global";
import MemberController from "@/api/member";

export default {
    mixins: [modalMixin],
    data() {
        return {
            filter: "",
            showLoader: true,
            isMobile: false,
            width: window.innerWidth,
            height: window.innerHeight,
            memberRole: [],
            locationList: [],
            userId: null,
            fields: [
                {
                    key: "User",
                    label: this.$i18n.t("users.name"),
                    sortable: true,
                },
                {
                    key: "User.PhoneNumber",
                    label: this.$i18n.t("users.phone"),
                    sortable: true,
                },
                {
                    key: "RolesString",
                    label: this.$i18n.t("staff.role"),
                    sortable: true,
                },
                {
                    key: "VisibleInIntercom",
                    label: "I",
                    sortable: false,
                    thClass: 'd-none', tdClass: 'd-none',
                },
                {
                    key: "actions",
                    label: "",
                    sortable: false,
                },
            ],
            perPage: 20,
            currentPage: 1,
            perPageOptions: [
                { id: 10, text: "10 " + this.$t("menu.per_page") },
                { id: 20, text: "20 " + this.$t("menu.per_page") },
                { id: 50, text: "50 " + this.$t("menu.per_page") },
            ],
            roleFlag: false,
        };
    },
    computed: {
        ...mapGetters(["getLocationMembersSorted", "getActiveCompany", "getActiveLocation", "getUserRoles", "getUser", "getActiveUserRole", "getInitProcessing"]),

        locationUsersList() {
            let membersList = this.getLocationMembersSorted.filter((item) =>
                (item.User.FirstName + " " + item.User.LastName)
                    .toLowerCase()
                    .includes(this.filter.toLowerCase())
            );

            return membersList;
        },
        paginatedList(){
            return this.locationUsersList.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            );
        }
    },
    async created() {
        //await this.$store.dispatch(LOCATION_GET, this.$route.params.locationId)
        await this.$store.dispatch(LOCATION_MEMBERS_GET, this.$route.params.locationId)

        this.showLoader = false;
        this.isMobile = window.addEventListener("resize", this.onResize);
        this.isMobile = window.addEventListener("onload", this.onLoad);

        if (this.$route.query?.showIntercom === '1')
            for(let field of this.fields)
                if (field.key === "VisibleInIntercom") {
                    field.thClass = ''
                    field.tdClass = ''
                }

        // wait for init completed
        for (let i = 0; i < 30; i++) {
            await sleep(100)
            if (this.getInitProcessing === false) {
                break
            }
        }

        this.userId = this.getUser?.Id

        if( this.getActiveUserRole == "CompanyOwner" || this.getActiveUserRole == "CompanyManager" || this.getActiveUserRole == "LocationManager" || this.getActiveUserRole == "KeyManager"){
            this.roleFlag = true
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("onload", this.onLoad);
        this.isMobile = false;
    },
    methods: {
        disableChangeRole(maxRole) {
            let aRole = this.getActiveUserRole
            if (!aRole)
                return true
            return (aRole == maxRole && (aRole != ROLE_COMPANY_OWNER || aRole != ROLE_COMPANY_MANAGER)) ||
                (rolePriorityByName(aRole) >= rolePriorityByName(maxRole)) ||
                (rolePriorityByName(ROLE_KEY_PERMANENT) <= rolePriorityByName(aRole)) ||
                (aRole === ROLE_DEVICE_INSTALLER)
        },
        disableRemoveRole(maxRole) {
            let aRole = this.getActiveUserRole
            if (!aRole)
                return true
            return (aRole == maxRole && (aRole != ROLE_COMPANY_OWNER || aRole != ROLE_COMPANY_MANAGER)) ||
                (rolePriorityByName(aRole) >= rolePriorityByName(maxRole)) ||
                (rolePriorityByName(ROLE_KEY_PERMANENT) <= rolePriorityByName(aRole)) ||
                (aRole === ROLE_DEVICE_INSTALLER)
        },
        async VisibleInIntercomChange(memberIds, visible) {
            for (const memberId of memberIds) {
                if (memberId)
                    await MemberController.updateMemberIntercom(memberId, visible ? 0 : 1);
            }
        },
        inviteMember() {
            this.showModal(null, M_MEMBER_INVITE_FORM);
        },
        createInvite() {
            this.showModal({ staffInvite: null }, M_COMPANY_LOCATION_INVITE_FORM);
        },
        changeRole(user) {
            user["CompanyId"] = this.$route.params.companyId;
            this.showModal({ user: user }, M_LOCATION_CHANGE_ROLE_FORM);
        },
        deleteUser(user) {
            this.showModal({ user: user }, M_LOCATION_USER_DELETE);
        },
        getRoleObj(id) {
            return definedRolesArray().find((role) => role.Id == id);
        },
        userFName(FirstName){
            if(FirstName === null || FirstName === "null" || FirstName === ""){
                FirstName = '';
            }
            return FirstName;
        },
        userLName(LastName){
            if(LastName === null  || LastName === "null" || LastName === ""){
                LastName = '';
            }
            return LastName;
        },
        onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            if(this.width < 1200){
                this.isMobile = true
            }else{
                this.isMobile = false
            }
            return this.isMobile;
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }
            return this.isMobile;
        },
    },
    components: {
        "zs-cancel-invite-modal": CancelInviteMemberModal,
        "zs-delete-key-user-modal": DeleteKeyUser,
        "zs-location-invite-modal": InviteLocationEmployeeModal,
        "zs-change-location-role-modal": ChangeLocationEmployeeRole,
        "zs-delete-location-user-modal": DeleteLocationUserModal,
        EmptyList,
        Select2,
    },
};
</script>
<style scoped>
.btn:focus{
   box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>
